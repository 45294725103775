<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "menJin",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到门禁页",
                            "#按图1说明参数:",
                            "1.必填项-技能:打BOSS的输出技能,建议用最高输出套路",
                            "2.必填项-选择难度:普通或困难根据实际选择",
                            "3.必填项-角色挂机:勾选后,角色只会跟着加入进门禁,拾取奖励,但是不会靠近boss输出",
                            "4.必填项-拾取:可以设置只拾取宗门奖励包或全部拾取(确保背包充足)",
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#任务栏要完全显示(图1)",
                            "#默认不躲BOSS技能,死亡自动就近",
                            "#建议跟正常队伍组队,不要单刷,BOSS秒人技能过不去",
                            "#游戏提前组好队伍,根据队伍设置难度",
                            '以上设置好,队长开本后,开始任务',
                        ],
                        img:[
                            "2.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            setTimeout(res =>{
                this.$refs.row.initData(this.list);
            },100)
        }
    }
</script>

<style scoped>

</style>
